import React from 'react'

import AppContext from '../contexts/AppContext'
import locale from '../services/locale'

import '../stylesheets/components/Cookies.scss'

export default class Cookies extends React.Component {
    state = {}
    static contextType = AppContext
    handleHide = () => {
        if (this.context.accept) this.context.accept()
    }
    render() {
        return (
            <div className="Cookies">
                <div className="Cookies_holder">
                    <div className="Cookies_window">
                        <p>{locale.translate('cookies_description')}</p>
                        <button onClick={this.handleHide}>
                            {locale.translate('cookies_button')}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
