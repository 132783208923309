const identifier = 'bookmarks'

const get = () => {
    return JSON.parse(window.localStorage.getItem(identifier) || '[]')
}

const store = (data) => {
    return window.localStorage.setItem(identifier, JSON.stringify(data))
}

const storage = {
    get: () => {
        return get()
    },
    has: (key) => {
        const data = get()
        return data.indexOf(key) > -1
    },
    add: (key) => {
        const data = get()
        if (data.indexOf(key) < 0) data.push(key)
        store(data)
        return data
    },
    set: (data) => {
        store(data)
    },
    remove: (key) => {
        const data = get()
        if (data.indexOf(key) > -1) data.splice(data.indexOf(key), 1)
        store(data)
        return data
    },
    toggle: (key) => {
        const data = get()
        if (data.indexOf(key) < 0) {
            data.push(key)
        } else {
            data.splice(data.indexOf(key), 1)
        }
        store(data)
        return data
    },
}

export default storage
