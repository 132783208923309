import React from 'react'

export default class Error extends React.Component {
    state = { error: false }
    static getDerivedStateFromError(error) {
        return { error: true }
    }
    render() {
        if (this.state.error) return ''
        return this.props.children
    }
}
