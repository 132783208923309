import translations from '../shared/translations'

const identifier = 'locale'

const get = () => {
    return window.localStorage.getItem(identifier) || 'nl'
}

const store = (data) => {
    return window.localStorage.setItem(identifier, data)
}

const locale = {
    get: () => {
        return get()
    },
    set: (locale) => {
        return store(locale)
    },
    translate: (key, data = {}, lang = null) => {
        let string = translations[key][lang || get()]
        for (const [key, value] of Object.entries(data)) {
            string = string.replaceAll(`:${key}`, value)
        }
        return string
    },
}

export default locale
