import React from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'

import storage from '../services/storage'
import seen from '../services/seen'
import locale from '../services/locale'

import '../stylesheets/components/Tile.scss'

export default class Tile extends React.Component {
    state = {}
    handleClick = (e) => {
        e.preventDefault()
        this.props.toggle(this.props.item.id)
    }
    render() {
        return (
            <Link
                className={cx(
                    'Tile',
                    `Tile_${this.props.item.type}`,
                    this.props.item.icon ? `Tile_icon_${this.props.item.icon.toLowerCase()}` : null,
                    { Tile_has_icon: !!this.props.item.icon, Tile_handle: !!this.props.bookmark },
                )}
                to={`/${this.props.item.id}`}>
                <div className="Tile_information">
                    <h3>{this.props.item.title}</h3>
                </div>
                {!!this.props.toggle && this.props.item.type === 'article' && (
                    <div
                        className={cx('Tile_bookmark', {
                            Tile_active: storage.has(this.props.item.id),
                        })}
                        onClick={this.handleClick}>
                        <div className="Tile_icon">
                            <div className="Tile_line" />
                            <div className="Tile_line" />
                        </div>
                    </div>
                )}
                {this.props.item.type === 'article' && (
                    <div
                        className={cx('Tile_new', {
                            Tile_visible: this.props.item.new && !seen.has(this.props.item.id),
                        })}>
                        {locale.translate('category_new')}
                    </div>
                )}
            </Link>
        )
    }
}
