export function register() {
    let refreshing
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.addEventListener('controllerchange', function () {
            if (refreshing) return
            window.location.reload()
            refreshing = true
        })
        navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/sw.js`).then((registration) => {
            registration.addEventListener('updatefound', () => {
                const worker = registration.installing
                worker.addEventListener('statechange', () => {
                    if (worker.state === 'installed' && navigator.serviceWorker.controller) {
                        if (
                            window.confirm(
                                'Er is een nieuwe versie beschikbaar. Wil je de applicatie herladen?',
                            )
                        ) {
                            worker.postMessage({ action: 'skip' })
                        }
                    }
                })
            })
        })
    }
}
