import axios from 'axios'

import locale from '../services/locale'

const call = {
    get: (url, data = {}) => {
        return axios({
            method: 'GET',
            url:
                process.env.REACT_APP_ENDPOINT +
                url +
                '?' +
                Object.entries(data)
                    .map(([key, val]) => `${key}=${val}`)
                    .join('&'),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Locale: locale.get(),
                Authorization: `Bearer ${window.localStorage.getItem('token') || ''}`,
            },
            data: {},
        })
    },
    post: (url, data = {}) => {
        return axios({
            method: 'POST',
            url: process.env.REACT_APP_ENDPOINT + url,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Locale: locale.get(),
                Authorization: `Bearer ${window.localStorage.getItem('token') || ''}`,
            },
            data,
        })
    },
}

const api = {
    structure: () => {
        return call.get('/structure', {})
    },
    article: (id) => {
        return call.get(`/article/${id}`, {})
    },
    access: (email) => {
        return call.post('/access', { email })
    },
    signup: (email, coupon) => {
        return call.post('/signup', { email, coupon })
    },
    code: (email, token, code) => {
        return call.post('/code', { email, token, code })
    },
    confirm: (name, email, position, coupon, token, code) => {
        return call.post('/confirm', { name, email, position, coupon, token, code })
    },
    launch: (code) => {
        return call.post('/launch', { code })
    },
    payment: (id) => {
        return call.get(`/payment/${id}`)
    },
}

export default api
