import React from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'

import locale from '../services/locale'
import api from '../services/api'

import '../stylesheets/pages/Thanks.scss'

export default class Thanks extends React.Component {
    state = {
        status: undefined,
    }
    componentDidMount = async () => {
        try {
            const response = await api.payment(
                new URLSearchParams(this.props.location.search).get('id') || '',
            )
            this.setState({ status: response.data.status })
        } catch (e) {
            this.setState({ status: 'error' })
        }
    }
    render() {
        return this.state.status ? (
            <div className="Thanks">
                <div className="Thanks_window">
                    <div className="Thanks_form">
                        <div className={cx('Thanks_status', `Thanks_status_${this.state.status}`)}>
                            {locale.translate(`thanks_status_${this.state.status}`)}
                        </div>
                        {this.state.status === 'paid' && (
                            <div className="Thanks_link">
                                <p>
                                    <Link to="/login">{locale.translate('thanks_link')}</Link>
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        ) : null
    }
}
