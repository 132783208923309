import React from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'

import locale from '../services/locale'
import api from '../services/api'

import '../stylesheets/pages/Confirm.scss'

const price = (value, locale) =>
    new Intl.NumberFormat('nl', {
        style: 'currency',
        currency: 'EUR',
    }).format(value)

export default class Confirm extends React.Component {
    state = {
        loading: false,
        code: '',
    }
    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({ loading: true })
        const url = new URLSearchParams(this.props.location.search)
        api.confirm(
            url.get('name') || '',
            url.get('email') || '',
            url.get('position') || '',
            url.get('coupon') || '',
            url.get('token') || '',
            this.state.code,
        )
            .then((response) => {
                window.location.replace(response.data.url)
            })
            .catch((e) => {
                console.log(e)
                this.setState({ loading: false })
                alert(locale.translate('confirm_error'))
            })
    }
    handleCodeChange = (e) => {
        this.setState({ code: e.target.value.replace(/\D/g, '').slice(0, 4) })
    }
    render() {
        return (
            <div className="Confirm">
                <div className="Confirm_window">
                    <div className="Confirm_form">
                        <form onSubmit={this.handleSubmit}>
                            <div className="Confirm_field">
                                <input
                                    type="text"
                                    placeholder={locale.translate('confirm_code_placeholder')}
                                    value={this.state.code}
                                    onChange={(e) => this.handleCodeChange(e)}
                                />
                            </div>
                            <div className="Confirm_submit">
                                <button
                                    type="submit"
                                    disabled={this.state.loading || !this.state.code.length}
                                    className={cx({
                                        Confirm_loading: this.state.loading,
                                    })}>
                                    {locale.translate('confirm_button')} (
                                    {price(
                                        new URLSearchParams(this.props.location.search).get(
                                            'price',
                                        ) || '',
                                        locale.get(),
                                    )}
                                    )
                                </button>
                            </div>
                            <div className="Confirm_link">
                                <p>
                                    {locale.translate('confirm_link_introduction')}{' '}
                                    <Link to="/signup">
                                        {locale.translate('confirm_link_action')}
                                    </Link>
                                    .
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
