import React from 'react'
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc'
import arrayMove from 'array-move'

import storage from '../services/storage'
import Tile from '../components/Tile'
import AppContext from '../contexts/AppContext'
import locale from '../services/locale'

import '../stylesheets/pages/Bookmarks.scss'

const DragHandle = sortableHandle(() => (
    <div className="Bookmarks_drag">
        <div className="Bookmarks_lines">
            <div className="Bookmarks_line"></div>
            <div className="Bookmarks_line"></div>
            <div className="Bookmarks_line"></div>
        </div>
    </div>
))

const SortableItem = SortableElement((props) => (
    <div className="Bookmarks_tile">
        <Tile item={props.value} toggle={props.call} bookmark={true} />
        <DragHandle />
    </div>
))

const SortableList = SortableContainer((props) => {
    return (
        <div className="Bookmarks_tiles">
            {props.items
                .filter((bookmark) => bookmark)
                .map((bookmark, index) => (
                    <SortableItem
                        key={bookmark.id}
                        index={index}
                        value={bookmark}
                        call={props.call}
                    />
                ))}
        </div>
    )
})

export default class Bookmarks extends React.Component {
    state = {
        storage: storage.get(),
    }
    handleToggle = (key) => {
        if (!storage.has(key) && !window.localStorage.getItem('hint')) {
            window.localStorage.setItem('hint', 1)
            this.context.show(
                true,
                locale.translate('bookmarked_title'),
                locale.translate('bookmarked_content'),
            )
        }
        this.setState({ storage: storage.toggle(key) })
    }
    handleSort = ({ oldIndex, newIndex }) => {
        this.setState(({ storage }) => ({
            storage: arrayMove(storage, oldIndex, newIndex),
        }))
    }
    componentWillUnmount = () => {
        storage.set(
            this.state.storage.filter((bookmark) => bookmark).filter((id) => storage.has(id)),
        )
    }
    static contextType = AppContext
    render() {
        const items = this.state.storage
            .map((item) => this.props.structure.find((option) => option.id === item))
            .filter((item) => !!item)
        return (
            <div className="Bookmarks">
                <div className="Bookmarks_count">
                    <p>
                        {!items.length
                            ? locale.translate('bookmarks_results_none')
                            : items.length === 1
                            ? locale.translate('bookmarks_results_singular', {
                                  count: items.length,
                              })
                            : locale.translate('bookmarks_results_plural', { count: items.length })}
                    </p>
                </div>
                <SortableList
                    axis="y"
                    lockAxis="y"
                    useDragHandle={true}
                    items={items}
                    onSortEnd={this.handleSort}
                    call={this.handleToggle}
                />
            </div>
        )
    }
}
