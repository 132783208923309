import React from 'react'
import ReactGA from 'react-ga4'

import Tile from '../components/Tile'
import Rich from '../components/Rich'
import AppContext from '../contexts/AppContext'

import '../stylesheets/pages/Category.scss'

export default class Category extends React.Component {
    state = {
        notify: !!this.context.notification && !this.props.id,
    }
    static contextType = AppContext
    componentDidMount = () => {
        if (this.props.id)
            ReactGA.event({
                category: 'NAVIGATION',
                action: 'NAVIGATE_CATEGORY',
                label: this.props.item.title,
            })
    }
    render() {
        const items = this.props.structure.filter((item) => item.parent === this.props.id)
        return (
            <div className="Category">
                <div className="Category_tiles">
                    {this.state.notify && (
                        <div className="Category_tile">
                            <div className="Category_notification">
                                <Rich>
                                    {this.context.notification.message.replace(
                                        /(?:\r\n|\r|\n)/g,
                                        '<br>',
                                    )}
                                </Rich>
                            </div>
                        </div>
                    )}
                    {items.map((item, key) => (
                        <div className="Category_tile" key={key}>
                            <Tile item={item} />
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}
