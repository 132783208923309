import React from 'react'
import Fuse from 'fuse.js'

import storage from '../services/storage'
import Tile from '../components/Tile'
import AppContext from '../contexts/AppContext'
import locale from '../services/locale'

import '../stylesheets/pages/Search.scss'

export default class Search extends React.Component {
    state = {
        storage: storage.get(),
    }
    handleToggle = (key) => {
        if (!storage.has(key) && !window.localStorage.getItem('hint')) {
            window.localStorage.setItem('hint', 1)
            this.context.show(
                true,
                locale.translate('bookmarked_title'),
                locale.translate('bookmarked_content'),
            )
        }
        this.setState({ storage: storage.toggle(key) })
    }
    static contextType = AppContext
    render() {
        const fuse = new Fuse(this.props.structure, {
            includeScore: true,
            threshold: 0.5,
            keys: ['title'],
        })
        const items = fuse.search(this.props.query).map((item) => item.item)
        return (
            <div className="Search">
                <div className="Search_count">
                    <p>
                        {!items.length
                            ? locale.translate('search_results_none')
                            : items.length === 1
                            ? locale.translate('search_results_singular', {
                                  count: items.length,
                              })
                            : locale.translate('search_results_plural', { count: items.length })}
                    </p>
                </div>
                <div className="Search_tiles">
                    {items.map((item, key) => (
                        <div className="Search_tile" key={key}>
                            <Tile item={item} toggle={this.handleToggle} />
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}
