import React from 'react'

import Rich from '../components/Rich'
import AppContext from '../contexts/AppContext'

import '../stylesheets/pages/Disclaimer.scss'

export default class Disclaimer extends React.Component {
    state = {}
    static contextType = AppContext
    render() {
        return (
            <div className="Disclaimer">
                <Rich>{this.context.disclaimer}</Rich>
            </div>
        )
    }
}
