import React from 'react'

import Rich from '../components/Rich'
import AppContext from '../contexts/AppContext'

import '../stylesheets/pages/About.scss'

export default class About extends React.Component {
    state = {}
    static contextType = AppContext
    render() {
        return (
            <div className="About">
                <Rich>{this.context.about}</Rich>
            </div>
        )
    }
}
