import React from 'react'
import cx from 'classnames'

import '../stylesheets/components/Lock.scss'

export default class Lock extends React.Component {
    handleClick = (e) => {
        e.preventDefault()
        this.props.toggle()
    }
    render() {
        return (
            <div
                className={cx('Lock', {
                    Lock_overlay: this.props.overlay,
                    Lock_locked: this.props.locked,
                })}>
                <div className="Lock_area" onClick={this.handleClick}>
                    <div className="Lock_status"></div>
                    <div className="Lock_open"></div>
                    <div className="Lock_close"></div>
                </div>
            </div>
        )
    }
}
