import React from 'react'
import ReactGA from 'react-ga4'
import cx from 'classnames'
import * as Hammer from 'hammerjs'

import api from '../services/api'
import seen from '../services/seen'
import Lock from '../components/Lock'
import Drag from '../components/Drag'
import Rich from '../components/Rich'
import locale from '../services/locale'

import '../stylesheets/pages/Article.scss'

let t, g
let end = false

export default class Article extends React.Component {
    state = {
        article: undefined,
        open: false,
        step: 0,
        loaded: false,
        lock: false,
        scaling: false,
        scale: 1,
        x: 0.5,
        y: 0.5,
        ox: 0,
        oy: 0,
        overlay: false,
        locked: false,
        highlight: null,
        dragging: false,
        extra: false,
        go: false,
    }
    componentDidMount = async () => {
        ReactGA.event({
            category: 'OPERATION',
            action: 'OPEN',
            label: this.props.item.title,
        })
        seen.add(this.props.id)
        api.article(this.props.id)
            .then((response) => this.setState({ article: response.data }))
            .catch((e) => {
                console.log(e)
                if (e.response.status === 401) {
                    window.localStorage.removeItem('token')
                    this.props.history.push('/login')
                }
            })
        g = window.setTimeout(() => {
            this.setState({ go: true })
        }, 500)
        let hammer = new Hammer(document.getElementsByClassName('Article_slider')[0])
        let startPos = 0
        let x
        let y
        let memx
        let memy
        let memscale
        let offsetx
        let offsety
        hammer.add(new Hammer.Pan({ direction: Hammer.DIRECTION_ALL, threshold: 10 }))
        hammer.add(new Hammer.Pinch())
        hammer.on('pinchstart', (e) => {
            x = e.center.x
            y = e.center.y
            this.setState({
                scaling: true,
                x: (1 / window.innerWidth) * e.center.x,
                y: (1 / window.innerWidth) * e.center.y,
            })
        })
        hammer.on('pinchend', (e) => {
            t = window.setTimeout(() => {
                this.setState({ lock: true, scale: 1, ox: 0, oy: 0, scaling: false }, () => {
                    window.setTimeout(() => {
                        this.setState({ lock: false, x: 0.5, y: 0.5 })
                    }, 350)
                })
            }, 200)
        })
        hammer.on('pinch', (e) => {
            if (!this.state.lock) {
                memx = e.center.x
                memy = e.center.y
                memscale = e.scale
                let scale = e.scale
                let ox = -(x - e.center.x)
                let oy = -(y - e.center.y) + (e.scale - 1) * 50
                if (scale < 1) {
                    scale = 1
                    ox = 0
                    oy = 0
                }
                this.setState({ scale, ox, oy })
            }
        })
        var calculateXOffset = () => {
            var raw = window.getComputedStyle(
                document.getElementsByClassName('Article_container')[0],
            ).transform
            if (raw === 'none') return 0
            var parts = raw.split(', ')
            var last = parts[parts.length - 2]
            return parseInt(last)
        }
        hammer.on('panend', (e) => {
            if (this.state.scaling) {
                this.setState({ lock: true, scale: 1, ox: 0, oy: 0, scaling: false }, () => {
                    window.setTimeout(() => {
                        this.setState({ lock: false, x: 0.5, y: 0.5 })
                    }, 350)
                })
            }
        })
        hammer.on('panstart', (e) => {
            offsetx = e.deltaX
            offsety = e.deltaY
            if (!this.state.lock && this.state.scaling) {
                if (t) window.clearTimeout(t)
                let ox = -(x - memx - (e.deltaX - offsetx))
                let oy = -(y - memy - (e.deltaY - offsety)) + (memscale - 1) * 50
                this.setState({ ox, oy })
            }
        })
        hammer.on('pan', (e) => {
            if (!this.state.lock) {
                if (!this.state.scaling) {
                    if (!this.state.dragging) {
                        this.setState({ dragging: true })
                        startPos = calculateXOffset()
                    }
                    var delta = e.deltaX
                    var pos = startPos + delta
                    document.getElementsByClassName('Article_container')[0].style.transform =
                        'translateX(' + pos + 'px)'
                    if (e.isFinal) {
                        this.setState({ dragging: false }, () => {
                            if (this.state.step > 0 && delta >= 100) {
                                this.handleStepLeft()
                            } else if (
                                this.state.step < this.state.article.steps.length - 1 &&
                                delta <= -100
                            ) {
                                this.handleStepRight()
                            } else {
                                document.getElementsByClassName(
                                    'Article_container',
                                )[0].style.transform = 'translateX(' + startPos + 'px)'
                            }
                        })
                    }
                } else {
                    let ox = -(x - memx - (e.deltaX - offsetx))
                    let oy = -(y - memy - (e.deltaY - offsety)) + (memscale - 1) * 50
                    this.setState({ ox, oy })
                }
            }
        })
        document.addEventListener('keydown', this.handleKeyEvent)
    }
    componentDidUpdate = (prevProps, prevState) => {
        if (!prevState.overlay && this.state.overlay)
            ReactGA.event({
                category: 'OPERATION',
                action: 'SHOW_LAYERS',
                label: `${this.props.item.title} - ${
                    this.state.article.steps[this.state.step].title
                }`,
            })
        if (
            prevState.step !== this.state.step &&
            this.state.step === this.state.article.steps.length - 1
        )
            ReactGA.event({
                category: 'OPERATION',
                action: 'NAVIGATE_LAST_STEP',
                label: this.props.item.title,
            })
        if (this.state.highlight && prevState.highlight !== this.state.highlight)
            ReactGA.event({
                category: 'OPERATION',
                action: 'SHOW_HIGHLIGHT',
                label: `${this.props.item.title} - ${
                    this.state.article.steps[this.state.step].title
                } - ${
                    this.state.article.steps[this.state.step].highlights.find(
                        (highlight) => highlight.id === this.state.highlight,
                    ).title
                }`,
            })
        if (this.state.open && prevState.open !== this.state.open)
            ReactGA.event({
                category: 'OPERATION',
                action: 'SHOW_BACKGROUND_INFORMATION',
                label: `${this.props.item.title} - ${
                    this.state.article.steps[this.state.step].title
                }`,
            })
        if (document.querySelector('.Page_article .Drag_holder')) {
            if (this.state.open && prevState.open !== this.state.open) {
                document
                    .querySelector('.Page_article .Drag_holder')
                    .addEventListener('scroll', this.handleOpenScroll)
            } else {
                end = false
                document
                    .querySelector('.Page_article .Drag_holder')
                    .removeEventListener('scroll', this.handleOpenScroll)
            }
        }
    }
    componentWillUnmount = () => {
        if (g) window.clearTimeout(g)
        document.removeEventListener('keydown', this.handleKeyEvent)
    }
    handleOpenScroll = (e) => {
        if (e.target.scrollTop >= e.target.scrollHeight - e.target.offsetHeight) {
            if (!end)
                ReactGA.event({
                    category: 'OPERATION',
                    action: 'READ_BACKGROUND_INFORMATION',
                    label: `${this.props.item.title} - ${
                        this.state.article.steps[this.state.step].title
                    }`,
                })
            end = true
        }
    }
    handleKeyEvent = (e) => {
        if (e.keyCode === 32) this.handleToggleOpen()
        if (e.keyCode === 37) this.handleStepLeft()
        if (e.keyCode === 39) this.handleStepRight()
    }
    handleStepLeft = () => {
        let current = this.state.step
        let next = current - 1
        if (next < 0) next = 0
        this.setState({ step: next })
        if (next !== current) {
            this.setState({ extra: false, highlight: null })
            this.handleToggleOverlay(true)
        }
    }
    handleStepRight = () => {
        let current = this.state.step
        let next = current + 1
        if (next > this.state.article.steps.length - 1) next = this.state.article.steps.length - 1
        this.setState({ step: next })
        if (next !== current) {
            this.setState({ extra: false, highlight: null })
            this.handleToggleOverlay(true)
        }
    }
    handleToggleLocked = () => {
        this.setState((state) => ({
            overlay: !state.locked,
            locked: !state.locked,
            highlight: state.locked ? null : state.highlight,
        }))
    }
    handleToggleOpen = () => {
        this.setState((state) => ({ open: !state.open }))
    }
    handleImageLoaded = (e, key, wait) => {
        e.target.classList.add('Article_visible')
        if (!key && wait) this.setState((state) => ({ loaded: true }))
    }
    handleToggleOverlay = (prevent = false) => {
        this.setState((state) => ({
            overlay:
                prevent && !this.state.locked
                    ? false
                    : this.state.locked
                    ? state.overlay
                    : !state.overlay,
            highlight: null,
        }))
    }
    handleSelectHighlight = (e, id) => {
        if (this.state.overlay) {
            e.stopPropagation()
            this.setState({ highlight: id, extra: false })
        }
    }
    render() {
        const category =
            this.state.article &&
            this.props.structure.find(
                (item) =>
                    item.id ===
                    this.props.structure.find((item) => item.id === this.state.article.id).parent,
            )
        const step = this.state.article && this.state.article.steps[this.state.step]
        const highlight =
            step && this.state.highlight
                ? this.state.article.steps[this.state.step].highlights.find(
                      (highlight) => highlight.id === this.state.highlight,
                  )
                : null
        return (
            <div className="Article">
                <div className="Article_loaded">
                    <div className="Article_visual">
                        <div
                            className={cx('Article_slider', {
                                Article_animate: !this.state.dragging,
                                Article_scaling: this.state.scaling,
                            })}>
                            <div
                                className="Article_container"
                                style={{
                                    transform: 'translateX(-' + this.state.step * 100 + '%)',
                                }}>
                                {!!this.state.article &&
                                    this.state.article.steps.map((step, key) => (
                                        <div
                                            className="Article_slide"
                                            key={step.id}
                                            onClick={() => this.handleToggleOverlay()}>
                                            <div
                                                className={cx('Article_zoom', {
                                                    Article_transition: this.state.lock,
                                                })}
                                                style={{
                                                    transformOrigin: `${this.state.x * 100}% ${
                                                        this.state.y * 100
                                                    }%`,
                                                    transform: `translate3d(${this.state.ox}px, ${this.state.oy}px, 0) scale3d(${this.state.scale}, ${this.state.scale}, 100)`,
                                                }}>
                                                <div className="Article_image">
                                                    <img
                                                        className="Article_lazy"
                                                        src={step.image}
                                                        alt=""
                                                        onLoad={(e) =>
                                                            this.handleImageLoaded(e, key, true)
                                                        }
                                                        onError={(e) =>
                                                            this.handleImageLoaded(e, key, true)
                                                        }
                                                    />
                                                </div>
                                                {step.overlay && (
                                                    <div
                                                        className={cx('Article_overlay', {
                                                            Article_overlay_active:
                                                                this.state.overlay,
                                                        })}>
                                                        <img
                                                            className="Article_lazy"
                                                            src={step.overlay}
                                                            alt=""
                                                            onLoad={(e) =>
                                                                this.handleImageLoaded(
                                                                    e,
                                                                    key,
                                                                    false,
                                                                )
                                                            }
                                                            onError={(e) =>
                                                                this.handleImageLoaded(
                                                                    e,
                                                                    key,
                                                                    false,
                                                                )
                                                            }
                                                        />
                                                        {step.highlights.map((highlight) => (
                                                            <div
                                                                className={cx('Article_point', {
                                                                    Article_point_active:
                                                                        this.state.highlight ===
                                                                        highlight.id,
                                                                })}
                                                                style={{
                                                                    top: `calc(${highlight.y}% - 15px)`,
                                                                    left: `calc(${highlight.x}% - 15px)`,
                                                                    transform: `scale3d(${
                                                                        1 / this.state.scale
                                                                    }, ${1 / this.state.scale}, 1)`,
                                                                }}
                                                                key={highlight.id}
                                                                onClick={(e) =>
                                                                    this.handleSelectHighlight(
                                                                        e,
                                                                        highlight.id,
                                                                    )
                                                                }
                                                            />
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                        <div className="Article_lock">
                            <Lock
                                overlay={this.state.overlay}
                                locked={this.state.locked}
                                toggle={this.handleToggleLocked}
                            />
                        </div>
                        <div
                            className="Article_navigation Article_previous"
                            onClick={this.handleStepLeft}></div>
                        <div
                            className="Article_navigation Article_next"
                            onClick={this.handleStepRight}></div>
                        <div className="Article_indicators">
                            {!!this.state.article &&
                                this.state.article.steps.map((step, key) => (
                                    <div
                                        key={key}
                                        className={cx('Article_indicator', {
                                            Article_active: key === this.state.step,
                                        })}
                                    />
                                ))}
                        </div>
                    </div>
                    <div className="Article_desktop">
                        {!!this.state.article && (
                            <>
                                <div className="Article_plain">
                                    {highlight ? (
                                        <>
                                            <h2 className="Article_highlight">{highlight.title}</h2>
                                            <Rich>{highlight.description}</Rich>
                                        </>
                                    ) : (
                                        <>
                                            <h2>
                                                {locale.translate('article_step', {
                                                    count: this.state.step + 1,
                                                })}
                                                : {step.title}
                                            </h2>
                                            <div className="Article_ntk">
                                                <h3>{locale.translate('article_ntk')}</h3>
                                                <Rich>{step.need_to_know}</Rich>
                                                <ul className="Article_context">
                                                    <li>
                                                        {locale.translate('article_category')}:{' '}
                                                        {category.title}
                                                    </li>
                                                    <li>
                                                        {locale.translate('article_rt')}:{' '}
                                                        {this.state.article.reading_time}
                                                    </li>
                                                </ul>
                                            </div>
                                            <Rich>{step.description}</Rich>
                                            <hr />
                                            <Rich>{step.extra}</Rich>
                                            <div className="Article_author">
                                                <p>
                                                    {locale.translate('article_authors')}:{' '}
                                                    {step.authors}
                                                    <br />
                                                    {locale.translate('article_updated')}:{' '}
                                                    {step.last}
                                                </p>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                    <div className="Article_textual">
                        {!!this.state.article && (
                            <Drag
                                disabled={!!highlight}
                                open={this.state.open}
                                change={this.handleToggleOpen}>
                                {highlight ? (
                                    <>
                                        <h2 className="Article_highlight">{highlight.title}</h2>
                                        <Rich>{highlight.description}</Rich>
                                    </>
                                ) : (
                                    <>
                                        <h2>
                                            {locale.translate('article_step', {
                                                count: this.state.step + 1,
                                            })}
                                            : {step.title}
                                        </h2>
                                        {!!this.state.open && (
                                            <div className="Article_ntk">
                                                <h3>{locale.translate('article_ntk')}</h3>
                                                <Rich>{step.need_to_know}</Rich>
                                                <ul className="Article_context">
                                                    <li>
                                                        {locale.translate('article_category')}:{' '}
                                                        {category.title}
                                                    </li>
                                                    <li>
                                                        {locale.translate('article_rt')}:{' '}
                                                        {this.state.article.reading_time}
                                                    </li>
                                                </ul>
                                            </div>
                                        )}
                                        <Rich>
                                            {!this.state.open
                                                ? step.description
                                                : step.description + step.extra}
                                        </Rich>
                                        {!!this.state.open && (
                                            <div className="Article_author">
                                                <p>
                                                    {locale.translate('article_authors')}:{' '}
                                                    {step.authors}
                                                    <br />
                                                    {locale.translate('article_updated')}:{' '}
                                                    {step.last}
                                                </p>
                                            </div>
                                        )}
                                    </>
                                )}
                            </Drag>
                        )}
                    </div>
                </div>
                {(!this.state.article || !this.state.loaded) && (
                    <div className="Article_loading">
                        <div className="Article_visual">
                            <div className="Article_lock" />
                        </div>
                        <div className="Article_textual">
                            <h2>
                                <span>Pellentesque cursus sapien</span>
                            </h2>
                            <p>
                                <span>
                                    Pellentesque cursus sapien eu nulla pulvinar pretium. Proin
                                    tempus tincidunt orci, at egestas nisl mollis sed.
                                </span>
                            </p>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}
