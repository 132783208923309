import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import ReactGA from 'react-ga4'
import * as serviceWorker from './serviceWorker'

import Error from './components/Error'
import App from './components/App'
import locale from './services/locale'

import './stylesheets/index.scss'

const l = new URLSearchParams(window.location.search).get('locale')
if (l) {
    locale.set(l)
    window.location.replace(window.location.href.replace(window.location.search, ''))
}

ReactGA.initialize(process.env.REACT_APP_GA)
ReactDOM.render(
    !l ? (
        <Error>
            <Router>
                <Route component={App} />
            </Router>
        </Error>
    ) : null,
    document.getElementById('root'),
)
serviceWorker.register()
