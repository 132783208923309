import React from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'

import locale from '../services/locale'
import api from '../services/api'

import '../stylesheets/pages/Signup.scss'

export default class Signup extends React.Component {
    state = {
        loading: false,
        name: '',
        email: '',
        position: '',
        coupon: '',
    }
    handleSignup = (e) => {
        e.preventDefault()
        this.setState({ loading: true })
        api.signup(this.state.email, this.state.coupon)
            .then((response) => {
                this.setState({ loading: false })
                this.props.history.push(
                    `/confirm?${new URLSearchParams({
                        name: this.state.name,
                        email: this.state.email,
                        position: this.state.position,
                        coupon: this.state.coupon,
                        token: response.data.token,
                        price: response.data.price,
                    }).toString()}`,
                )
            })
            .catch((e) => {
                console.log(e)
                this.setState({ loading: false })
                alert(locale.translate('signup_error'))
            })
    }
    handleChange = (type, e) => {
        this.setState({ [type]: e.target.value })
    }
    render() {
        return (
            <div className="Signup">
                <div className="Signup_window">
                    <div className="Signup_form">
                        <form onSubmit={this.handleSignup}>
                            <div className="Signup_link">
                                <p>{locale.translate('signup_introduction')}</p>
                            </div>
                            <div className="Signup_field">
                                <input
                                    type="text"
                                    placeholder={locale.translate('signup_name_placeholder')}
                                    value={this.state.name}
                                    onChange={(e) => this.handleChange('name', e)}
                                />
                            </div>
                            <div className="Signup_field">
                                <input
                                    type="text"
                                    placeholder={locale.translate('signup_email_placeholder')}
                                    value={this.state.email}
                                    onChange={(e) => this.handleChange('email', e)}
                                />
                            </div>
                            <div className="Signup_field">
                                <select
                                    value={this.state.position}
                                    onChange={(e) => this.handleChange('position', e)}
                                    className={cx({
                                        Signup_placeholder: !this.state.position.length,
                                    })}>
                                    <option value="">
                                        {locale.translate('signup_position_placeholder')}
                                    </option>
                                    {[
                                        'student',
                                        'physician_assistant',
                                        'medical_specialist',
                                        'surgery_assistant',
                                        'paramedic',
                                        'other',
                                    ].map((item, key) => (
                                        <option
                                            key={key}
                                            value={locale.translate(
                                                'signup_position_value_' + item,
                                                {},
                                                'nl',
                                            )}>
                                            {locale.translate('signup_position_value_' + item)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="Signup_field">
                                <input
                                    type="text"
                                    placeholder={locale.translate('signup_coupon_placeholder')}
                                    value={this.state.coupon}
                                    onChange={(e) => this.handleChange('coupon', e)}
                                />
                            </div>
                            <div className="Signup_submit">
                                <button
                                    type="submit"
                                    disabled={
                                        this.state.loading ||
                                        !this.state.name.length ||
                                        !this.state.email.length ||
                                        !this.state.position.length
                                    }
                                    className={cx({
                                        Signup_loading: this.state.loading,
                                    })}>
                                    {locale.translate('signup_button')}
                                </button>
                            </div>
                            <div className="Signup_switch">
                                <p>
                                    {locale.translate('signup_hint')}{' '}
                                    <Link to="/login">{locale.translate('signup_switch')}</Link>
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
