import React from 'react'
import cx from 'classnames'

import locale from '../services/locale'

import '../stylesheets/components/Onboarding.scss'

const titles = [
    locale.translate('onboarding_step_1'),
    locale.translate('onboarding_step_2'),
    locale.translate('onboarding_step_3'),
    locale.translate('onboarding_step_4'),
]

export default class Onboarding extends React.Component {
    state = {
        show: false,
        step: 0,
    }
    componentDidMount = () => {
        this.handleTrigger()
    }
    componentDidUpdate = () => {
        this.handleTrigger()
    }
    handleTrigger = () => {
        const item = this.props.structure.find((item) => item.id === this.props.id)
        if (
            !!item &&
            item.type === 'article' &&
            !window.localStorage.getItem('onboarding') &&
            !this.state.show
        ) {
            window.localStorage.setItem('onboarding', 1)
            this.setState({ show: true })
        }
    }
    handleNext = () => {
        if (this.state.step < 3) {
            this.setState((state) => ({ step: state.step + 1 }))
        } else {
            this.setState({ show: false })
        }
    }
    render() {
        return (
            <div className={cx('Onboarding', { Onboarding_active: this.state.show })}>
                <div className="Onboarding_canvas">
                    <div className="Onboarding_description">
                        <p>{titles[this.state.step]}</p>
                    </div>
                    <div className="Onboarding_device">
                        <div
                            className="Onboarding_screen"
                            style={{
                                backgroundImage: `url('/images/onboarding/${this.state.step}.gif')`,
                            }}></div>
                    </div>
                    <div className="Onboarding_button" onClick={this.handleNext}>
                        {this.state.step > 2 ? <p>Starten</p> : <p>Volgende</p>}
                    </div>
                </div>
            </div>
        )
    }
}
