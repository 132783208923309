import React from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import ReactGA from 'react-ga4'

import config from '../shared/config'
import storage from '../services/storage'
import Shift from './Shift'
import Category from '../pages/Category'
import Article from '../pages/Article'
import Bookmarks from '../pages/Bookmarks'
import About from '../pages/About'
import Disclaimer from '../pages/Disclaimer'
import Search from '../pages/Search'
import Login from '../pages/Login'
import Signup from '../pages/Signup'
import Code from '../pages/Code'
import Confirm from '../pages/Confirm'
import Thanks from '../pages/Thanks'
import AppContext from '../contexts/AppContext'
import locale from '../services/locale'

import '../stylesheets/components/Page.scss'

const statics = [
    { id: 'bookmarks', type: 'bookmarks', title: locale.translate('bookmarks_title') },
    { id: 'about', type: 'about', title: locale.translate('about_title') },
    { id: 'disclaimer', type: 'disclaimer', title: locale.translate('disclaimer_title') },
    { id: 'login', type: 'login', title: locale.translate('login_title') },
    { id: 'signup', type: 'signup', title: locale.translate('signup_title') },
    { id: 'code', type: 'code', title: locale.translate('code_title') },
    { id: 'confirm', type: 'confirm', title: locale.translate('confirm_title') },
    { id: 'thanks', type: 'thanks', title: locale.translate('thanks_title') },
]

const components = {
    category: Category,
    article: Article,
    bookmarks: Bookmarks,
    about: About,
    disclaimer: Disclaimer,
    search: Search,
    login: Login,
    signup: Signup,
    code: Code,
    confirm: Confirm,
    thanks: Thanks,
}

export default class Page extends React.Component {
    state = {
        storage: storage.get(),
        menu: false,
        search: false,
        query: '',
    }
    static contextType = AppContext
    componentDidMount() {
        window.addEventListener('beforeunload', this.handleUnload)
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleUnload)
    }
    componentDidUpdate = (props) => {
        if (this.props.location !== props.location) {
            this.setState({ menu: false, query: '', search: false })
            window.localStorage.setItem('previous', props.location.pathname)
        }
    }
    handleUnload = () => {
        window.localStorage.removeItem('previous')
    }
    handleToggleLocale = (e) => {
        e.stopPropagation()
        const to = locale.get() === 'nl' ? 'en' : 'nl'
        locale.set(to)
        window.location.reload()
    }
    handleQueryChange = (e) => {
        this.setState({ query: e.target.value })
    }
    handleQueryClear = (e) => {
        this.setState({ query: '' })
    }
    handleMenuToggle = (e) => {
        this.setState((state) => ({ menu: !state.menu }))
    }
    handleBookmark = (e) => {
        if (!storage.has(this.props.match.params.id) && !window.localStorage.getItem('hint')) {
            window.localStorage.setItem('hint', 1)
            this.context.show(
                true,
                locale.translate('bookmarked_title'),
                locale.translate('bookmarked_content'),
            )
        }
        this.setState({ storage: storage.toggle(this.props.match.params.id) })
    }
    handleBack = (e) => {
        const item = [...this.props.structure, ...statics].find(
            (item) => item.id === this.props.match.params.id,
        )
        const to = item.parent ? `/${item.parent}` : '/'
        if (to === window.localStorage.getItem('previous')) {
            this.props.history.goBack()
        } else {
            this.props.history.push(to)
        }
    }
    handleSearchFocus = (e) => {
        this.setState({ search: true, menu: false })
    }
    handleSearchClose = (e) => {
        this.setState({ search: false, query: '' })
    }
    handleDirectionClick = (e) => {
        if (
            ![...this.props.structure, ...statics].find(
                (item) => item.id === this.props.match.params.id,
            ) ||
            !this.props.auth
        ) {
            this.handleMenuToggle()
        } else {
            this.handleBack()
        }
    }
    handleShare = (e) => {
        e.stopPropagation()
        e.preventDefault()
        try {
            navigator
                .share({
                    title: 'Anapptomy',
                    url: window.location.href + '?utm_source=share',
                })
                .then(() =>
                    ReactGA.event({
                        category: 'NAVIGATION',
                        action: 'SHARE',
                    }),
                )
                .catch()
        } catch (err) {}
    }
    handleLogout = (e) => {
        e.stopPropagation()
        e.preventDefault()
        window.localStorage.removeItem('token')
        this.props.history.push('/login')
    }
    render() {
        const structure = this.props.structure
        const query = this.state.query
        const item = [...this.props.structure, ...statics].find(
            (item) => item.id === this.props.match.params.id,
        )
        const mode = (item && item.type) || 'category'
        return (
            <div
                className={cx('Page', `Page_${mode}`, {
                    Page_initial: !item && !this.state.search,
                    Page_search: this.state.search,
                    Page_menu: this.state.menu,
                })}>
                <div className="Page_body">
                    <Shift
                        location={this.props.location}
                        initial={{ opacity: 1, transform: `translateX(0%)` }}
                        from={() =>
                            this.props.history.action === 'PUSH'
                                ? {
                                      opacity: 1,
                                      transform: `translateX(100%)`,
                                  }
                                : {
                                      opacity: 1,
                                      transform: `translateX(-100%)`,
                                  }
                        }
                        enter={{ opacity: 1, transform: `translateX(0%)` }}
                        leave={() =>
                            this.props.history.action === 'PUSH'
                                ? {
                                      opacity: 0,
                                      transform: `translateX(-100%)`,
                                  }
                                : {
                                      opacity: 0,
                                      transform: `translateX(100%)`,
                                  }
                        }
                        render={(id, style, props) => {
                            const item = [...structure, ...statics].find(
                                (item) => item.id === props.match.params.id,
                            )
                            const type = query.length ? 'search' : (item && item.type) || 'category'
                            const Component = components[type]
                            return (
                                <div className="Page_content" style={style}>
                                    <Component
                                        id={id}
                                        structure={structure}
                                        item={item}
                                        query={query}
                                        location={this.props.location}
                                        history={this.props.history}
                                    />
                                </div>
                            )
                        }}
                    />
                </div>
                <div className="Page_menu" onClick={this.handleMenuToggle}>
                    <ul>
                        {!this.props.auth && (
                            <>
                                <li>
                                    <Link to="/login">{locale.translate('navigation_login')}</Link>
                                </li>
                                <li>
                                    <Link to="/signup">
                                        {locale.translate('navigation_signup')}
                                    </Link>
                                </li>
                            </>
                        )}
                        {this.props.auth && (
                            <li>
                                <Link to="/bookmarks">
                                    {locale.translate('navigation_bookmarks')}
                                </Link>
                            </li>
                        )}
                        <li>
                            <a href="#share" onClick={this.handleShare}>
                                {locale.translate('navigation_share')}
                            </a>
                        </li>
                        <li>
                            <Link to="/about">{locale.translate('navigation_about')}</Link>
                        </li>
                        <li>
                            <Link to="/disclaimer">
                                {locale.translate('navigation_disclaimer')}
                            </Link>
                        </li>
                        {this.props.auth && (
                            <li>
                                <a href="#logout" onClick={this.handleLogout}>
                                    {locale.translate('navigation_logout')}
                                </a>
                            </li>
                        )}
                        <li onClick={this.handleToggleLocale}>
                            <div className="Page_locale">
                                {locale.get() === 'nl' ? (
                                    <img src="/images/en.svg" alt="" />
                                ) : (
                                    <img src="/images/nl.svg" alt="" />
                                )}
                            </div>
                        </li>
                    </ul>
                    <div className="Page_version">
                        <p>{config.version}</p>
                    </div>
                </div>
                <div className="Page_header">
                    <div className="Page_navigation">
                        <div className="Page_title">
                            <Shift
                                location={this.props.location}
                                initial={{ opacity: 1 }}
                                from={{ opacity: 0 }}
                                enter={{ opacity: 1 }}
                                leave={{ opacity: 0 }}
                                render={(id, style, props) => {
                                    const item = [...structure, ...statics].find(
                                        (item) => item.id === props.match.params.id,
                                    )
                                    return (
                                        <h1 className={cx({ Page_initial: !item })} style={style}>
                                            {(item && item.title) || 'Anapptomy'}
                                        </h1>
                                    )
                                }}
                            />
                        </div>
                        <div
                            className={cx('Page_direction', {
                                Page_back: !!item && this.props.auth,
                            })}
                            onClick={this.handleDirectionClick}>
                            <div className="Page_one" />
                            <div className="Page_two" />
                            <div className="Page_three" />
                        </div>
                        <div className="Page_action">
                            <input
                                type="text"
                                onFocus={this.handleSearchFocus}
                                onChange={this.handleQueryChange}
                                value={this.state.query}
                                placeholder={locale.translate('search_placeholder')}
                            />
                            <div
                                className={cx('Page_icon', {
                                    Page_hide: !this.props.auth,
                                    Page_bookmark: storage.has(this.props.id),
                                    Page_category: mode !== 'article',
                                    Page_article: mode === 'article',
                                })}
                                onClick={this.handleBookmark}>
                                <div className="Page_one" />
                                <div className="Page_two" />
                                <div className="Page_three" />
                                <div className="Page_four" />
                            </div>
                            <div className="Page_close" onClick={this.handleSearchClose}>
                                <p>{locale.translate('search_close')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
