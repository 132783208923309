import React from 'react'

const translations = {
    navigation_login: { nl: 'Inloggen', en: 'Log in' },
    navigation_signup: { nl: 'Aanmelden', en: 'Sign up' },
    navigation_bookmarks: { nl: 'Mijn operaties', en: 'My operations' },
    navigation_share: { nl: 'Delen', en: 'Share' },
    navigation_about: { nl: 'Over Anapptomy', en: 'About Anapptomy' },
    navigation_disclaimer: { nl: 'Disclaimer', en: 'Disclaimer' },
    navigation_logout: { nl: 'Uitloggen', en: 'Log out' },
    search_placeholder: { nl: 'Zoek operatie', en: 'Search operation' },
    search_close: { nl: 'Sluiten', en: 'Close' },
    search_results_none: { nl: 'Geen resultaten gevonden', en: 'No results found' },
    search_results_singular: { nl: ':count resultaat gevonden', en: ':count result found' },
    search_results_plural: { nl: ':count resultaten gevonden', en: ':count results found' },
    bookmarks_title: { nl: 'Mijn operaties', en: 'My operations' },
    bookmarks_results_none: { nl: 'Geen operaties opgeslagen', en: 'No operations saved' },
    bookmarks_results_singular: { nl: ':count operatie gevonden', en: ':count operation found' },
    bookmarks_results_plural: { nl: ':count operaties gevonden', en: ':count operations found' },
    about_title: { nl: 'Over Anapptomy', en: 'About Anapptomy' },
    disclaimer_title: { nl: 'Disclaimer', en: 'Disclaimer' },
    category_new: { nl: 'Nieuw', en: 'New' },
    article_step: { nl: 'Stap :count', en: 'Step :count' },
    article_open: { nl: 'Verdiepende informatie', en: 'In-depth information' },
    article_close: { nl: 'Verbergen', en: 'Hide' },
    article_ntk: { nl: 'Wat je moet weten', en: 'Need to know' },
    article_category: { nl: 'Categorie', en: 'Category' },
    article_rt: { nl: 'Leestijd (minuten)', en: 'Reading time (minutes)' },
    article_authors: { nl: 'Auteurs', en: 'Autors' },
    article_updated: { nl: 'Laatste update', en: 'Last update' },
    install_title: { nl: 'Bijna klaar voor gebruik', en: 'Almost ready to use' },
    install_content_ios: {
        nl: (
            <>
                Ga naar je browser instellingen en voeg Anapptomy toe aan je startscherm (
                <img src="/images/ios.svg" height="19" alt="" />
                ).
            </>
        ),
        en: (
            <>
                Go to your browser settings and select 'Add to Home Screen' (
                <img src="/images/ios.svg" height="19" alt="" />
                ).
            </>
        ),
    },
    install_content_android: {
        nl: (
            <>
                Ga naar je browser instellingen en voeg Anapptomy toe aan je apps (
                <img src="/images/android.svg" height="14" alt="" />
                ). Ga dan naar al je apps toe en open Anapptomy.
            </>
        ),
        en: (
            <>
                Go to your browser setting and add Anapptomy to your apps (
                <img src="/images/android.svg" height="14" alt="" />
                ). Then go to all your apps and open Anapptomy.
            </>
        ),
    },
    bookmarked_title: { nl: 'Toegevoegd aan favorieten', en: 'Added to favorites' },
    bookmarked_content: {
        nl: "Je kunt je favorieten terugvinden in het menu onder 'Mijn operaties'.",
        en: "You can find your favorites in the menu under 'My operations'.",
    },
    onboarding_step_1: {
        nl: 'Swipe tussen de operatiestappen',
        en: 'Swipe between the operation steps',
    },
    onboarding_step_2: { nl: 'Toon structuren', en: 'Show structures' },
    onboarding_step_3: {
        nl: 'Tap op een highlight voor meer information',
        en: 'Tap on a higlight for more information',
    },
    onboarding_step_4: {
        nl: 'Swipe omhoog voor verdiepende informatie',
        en: 'Swipe up for in-depth information',
    },
    message_button: { nl: 'Verder', en: 'Next' },
    cookies_description: {
        nl: 'Deze applicatie maakt gebruik van cookies om uw ervaring te verbeteren. We willen uw toestemming vragen voor het plaatsen van cookies die nodig zijn voor essentiële functies en het verzamelen van anonieme gegevens voor analyses. Door deze site te gebruiken, stemt u in met ons gebruik van cookies zoals beschreven in ons Privacybeleid. U kunt uw cookievoorkeuren op elk moment beheren via de cookie-instellingen van uw browser.',
        en: "This application uses cookies to improve your experience. We would like to ask your permission to place cookies that are necessary for essential functions and to collect anonymous data for analyses. By using this site you agree to our use of cookies as described in our Privacy Policy. You can manage your cookie preferences at any time via your browser's cookie settings.",
    },
    cookies_button: { nl: 'Cookies toestaan', en: 'Allow cookies' },
    login_title: { nl: 'Inloggen', en: 'Log in' },
    login_error: {
        nl: 'E-mailadres niet gevonden',
        en: 'Email address not found',
    },
    login_introduction: {
        nl: 'Als jouw instelling is aangesloten bij Anapptomy log dan in met het email adres van de desbetreffende instelling. Indien jij een persoonlijk abonnement hebt afgesloten log dan in met jouw eigen emailadres. Via de code in je email kan je inloggen.',
        en: 'If your institution is affiliated with Anapptomy, log in with the email address of the relevant institution. If you have taken out a personal subscription, log in with your own email address. You can log in using the code in your email.',
    },
    login_help: {
        nl: 'Mocht het inloggen niet lukken, neem met ons',
        en: 'If you are unable to log in, please',
    },
    login_contact: {
        nl: 'contact op',
        en: 'contact us',
    },
    login_email_placeholder: { nl: 'E-mailadres', en: 'Email address' },
    login_button: { nl: 'Stuur code', en: 'Send code' },
    login_hint: { nl: 'Nog geen account?', en: 'No account yet?' },
    login_switch: { nl: 'Sluit hier een abonnement af!', en: 'Subscribe here!' },
    signup_title: { nl: 'Aanmelden', en: 'Sign up' },
    signup_error: {
        nl: 'E-mailadres is al in gebruik',
        en: 'Email address already in use',
    },
    signup_introduction: {
        nl: 'Sluit nu een onbeperkt abonnement af voor €18,99! Vul hieronder jouw gegevens in om je account te registreren. Na de verificatie van je e-mail kom je op de betaalpagina.',
        en: 'Get an unlimited subscription now for €18.99! Enter your details below to register your account. After verifying your email, you will be taken to the payment page.',
    },
    signup_name_placeholder: { nl: 'Naam', en: 'Naam' },
    signup_email_placeholder: { nl: 'E-mailadres', en: 'Email address' },
    signup_position_placeholder: { nl: 'Functie', en: 'Position' },
    signup_coupon_placeholder: { nl: 'Kortingscode', en: 'Coupon code' },
    signup_position_value_student: { nl: 'Student', en: 'Student' },
    signup_position_value_physician_assistant: {
        nl: 'Arts-assistent',
        en: 'Physician assistant',
    },
    signup_position_value_medical_specialist: {
        nl: 'Medisch specialist',
        en: 'Medical specialist',
    },
    signup_position_value_surgery_assistant: { nl: 'OK assistent', en: 'Surgery assistant' },
    signup_position_value_paramedic: { nl: 'Paramedicus', en: 'Paramedic' },
    signup_position_value_other: { nl: 'Anders', en: 'Other' },
    signup_button: { nl: 'Naar controleren', en: 'Proceed to confirmation' },
    signup_hint: { nl: 'Al een account?', en: 'Got an account?' },
    signup_switch: { nl: 'Inloggen', en: 'Log in' },
    code_title: { nl: 'Voer de code in', en: 'Enter code' },
    code_error: {
        nl: 'Onjuiste code',
        en: 'Incorrect code',
    },
    code_code_placeholder: { nl: 'Code', en: 'Code' },
    code_button: { nl: 'Inloggen', en: 'Log in' },
    code_link_introduction: {
        nl: 'We hebben je per e-mail een toegangscode gestuurd. Niet ontvangen?',
        en: 'We sent you an access code via email. No email?',
    },
    code_link_action: { nl: 'Probeer het opnieuw', en: 'Try again' },
    confirm_title: { nl: 'Voer de code in', en: 'Enter code' },
    confirm_error: {
        nl: 'Onjuiste code',
        en: 'Incorrect code',
    },
    confirm_code_placeholder: { nl: 'Code', en: 'Code' },
    confirm_button: { nl: 'Naar betalen', en: 'To payment' },
    confirm_link_introduction: {
        nl: 'We hebben je per e-mail een toegangscode gestuurd. Niet ontvangen?',
        en: 'We sent you an access code via email. No email?',
    },
    confirm_link_action: { nl: 'Probeer het opnieuw', en: 'Try again' },
    thanks_title: { nl: 'Betaling', en: 'Payment' },
    thanks_status_open: {
        nl: 'De betaling wordt verwerkt. Zodra deze verwerkt is ontvang je een e-mail.',
        en: 'Oops, something went wrong',
    },
    thanks_status_canceled: { nl: 'De betaling is geannuleerd.', en: 'Oops, something went wrong' },
    thanks_status_pending: {
        nl: 'De betaling wordt verwerkt. Zodra deze verwerkt is ontvang je een e-mail.',
        en: 'The payment is being processed. You will receive an email as soon as this has been processed.',
    },
    thanks_status_expired: { nl: 'De betaling is verlopen.', en: 'The payment has expired.' },
    thanks_status_failed: { nl: 'De betaling is mislukt.', en: 'The payment failed.' },
    thanks_status_paid: {
        nl: 'Bedankt! De betaling is ontvangen. Je kunt nu direct inloggen met het opgegeven e-mailadres.',
        en: 'Thank you! Your payment has been received. You can now log in with the provided email address.',
    },
    thanks_status_error: { nl: 'Oeps, er ging iets mis.', en: 'Oops, something went wrong.' },
    thanks_link: { nl: 'Inloggen', en: 'Log in' },
    register_title: { nl: 'Registreren', en: 'Register' },
    register_error: {
        nl: 'Registeren mislukt',
        en: 'Failed to register',
    },
    register_name_placeholder: { nl: 'Naam', en: 'Naam' },
    register_username_placeholder: { nl: 'E-mailadres', en: 'Email address' },
    register_password_placeholder: { nl: 'Wachtwoord', en: 'Password' },
    register_position_placeholder: { nl: 'Functie', en: 'Position' },
    register_position_value_student: { nl: 'Student', en: 'Student' },
    register_position_value_physician_assistant: {
        nl: 'Arts-assistent',
        en: 'Physician assistant',
    },
    register_position_value_medical_specialist: {
        nl: 'Medisch specialist',
        en: 'Medical specialist',
    },
    register_position_value_surgery_assistant: { nl: 'OK assistent', en: 'Surgery assistant' },
    register_position_value_paramedic: { nl: 'Paramedicus', en: 'Paramedic' },
    register_position_value_other: { nl: 'Anders', en: 'Other' },
    register_button: { nl: 'Registeren', en: 'Register' },
    register_link_introduction: { nl: 'Al een account?', en: 'Got an account?' },
    register_link_action: { nl: 'Login', en: 'Log in' },
    forgot_title: { nl: 'Wachtwoord vergeten', en: 'Forgot password' },
    forgot_username_placeholder: { nl: 'E-mailadres', en: 'Email address' },
    forgot_button: { nl: 'Wachtwoord instellen', en: 'Reset password' },
    forgot_link_login: { nl: 'Inloggen', en: 'Log in' },
    forgot_success: {
        nl: 'Er is een e-mail naar het opgegeven adres verstuurd om uw wachtwoord opnieuw in te stellen',
        en: 'An email has been sent to the address provided to reset your password',
    },
    reset_title: { nl: 'Wachtwoord instellen', en: 'Reset password' },
    reset_username_placeholder: { nl: 'E-mailadres', en: 'Email address' },
    reset_password_placeholder: { nl: 'Kies wachtwoord', en: 'Choose password' },
    reset_button: { nl: 'Wachtwoord instellen', en: 'Reset password' },
    reset_link_login: { nl: 'Annuleren', en: 'Cancel' },
    reset_error: {
        nl: 'Wachtwoord instellen mislukt',
        en: 'Password reset failed',
    },
    reset_success: {
        nl: 'Wachtwoord is opnieuw ingesteld',
        en: 'Password reset Successful',
    },
}

export default translations
