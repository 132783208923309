import React from 'react'
import cx from 'classnames'
import * as Hammer from 'hammerjs'

import '../stylesheets/components/Drag.scss'

export default class Drag extends React.Component {
    state = {
        panning: false,
        animating: false,
        offset: 0,
    }
    componentDidMount = () => {
        const el = document.getElementsByClassName('Drag_drag')[0]
        let hammer = new Hammer(el)
        hammer.add(new Hammer.Pan({ direction: Hammer.DIRECTION_ALL, threshold: 0 }))
        hammer.add(new Hammer.Tap())
        hammer.on('tap', (e) => {
            this.handleToggleOpen()
        })
        hammer.on('panstart', (e) => {
            this.setState({ panning: true })
        })
        hammer.on('panend', (e) => {
            if (Math.abs(e.deltaY) >= 100) this.handleToggleOpen()
            this.setState({ panning: false, offset: 0, animating: true })
            window.setTimeout(() => {
                this.setState({ animating: false })
            }, 300)
        })
        hammer.on('pan', (e) => {
            const min = this.props.open ? 0 : -el.clientWidth + 60
            const max = this.props.open ? el.clientWidth - 60 : 0
            let offset = e.deltaY
            if (offset > max) offset = max
            if (offset < min) offset = min
            this.setState({ offset })
        })
    }
    handleToggleOpen = () => {
        this.props.change()
    }
    render() {
        return (
            <div
                className={cx('Drag', {
                    Drag_open: this.props.open,
                    Drag_panning: this.state.panning,
                    Drag_animating: this.state.animating,
                    Drag_disabled: this.props.disabled,
                })}>
                <div className="Drag_overlay" onClick={this.handleToggleOpen} />
                <div
                    className="Drag_mover"
                    style={{ transform: `translateY(${this.state.offset}px)` }}>
                    <div className="Drag_element">
                        <div className="Drag_holder">{this.props.children}</div>
                        <div className="Drag_drag" />
                        <div className="Drag_hint">
                            <p onClick={this.handleToggleOpen}>
                                <span>Meer informatie</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
