import React from 'react'
import { Switch, Route } from 'react-router-dom'

import api from '../services/api'
import Splash from '../components/Splash'
import Auth from '../components/Auth'
import Page from '../components/Page'
import Message from '../components/Message'
import Cookies from '../components/Cookies'
import Onboarding from '../components/Onboarding'
import AppContext from '../contexts/AppContext'
import locale from '../services/locale'

import '../stylesheets/components/App.scss'

export default class App extends React.Component {
    state = {
        structure: undefined,
        notification: undefined,
        about: undefined,
        disclaimer: undefined,
        title: null,
        body: null,
        show: false,
        skip: false,
        cookies: !window.localStorage.getItem('cookies'),
    }
    componentDidMount = () => {
        api.structure()
            .then((response) =>
                this.setState({
                    structure: response.data.structure,
                    notification: response.data.notification,
                    about: response.data.about,
                    disclaimer: response.data.disclaimer,
                }),
            )
            .catch((e) => console.log(e))
        const userAgent = window.navigator.userAgent.toLowerCase()
        const isIos = () => {
            return /iphone|ipad|ipod/.test(userAgent)
        }
        const isAndroid = () => {
            return userAgent.indexOf('android') > -1
        }
        const isTouchDevice = () => {
            return 'ontouchstart' in window || 'onmsgesturechange' in window
        }
        const isInStandaloneMode = () =>
            ('standalone' in window.navigator && window.navigator.standalone) ||
            window.matchMedia('(display-mode: fullscreen)').matches
        if (
            !isInStandaloneMode() &&
            isTouchDevice() &&
            process.env.NODE_ENV &&
            process.env.NODE_ENV === 'production'
        ) {
            if (isIos())
                this.handleShow(
                    false,
                    locale.translate('install_title'),
                    locale.translate('install_content_ios'),
                )
            if (isAndroid())
                this.handleShow(
                    false,
                    locale.translate('install_title'),
                    locale.translate('install_content_android'),
                )
        }
    }
    handleHide = () => {
        this.setState({ show: false })
    }
    handleShow = (skip, title, body) => {
        this.setState({ show: true, skip, title, body })
    }
    handleAccept = () => {
        window.localStorage.setItem('cookies', 1)
        this.setState({ cookies: false })
    }
    render() {
        return (
            <AppContext.Provider
                value={{
                    visible: this.state.show,
                    notification: this.state.notification,
                    about: this.state.about,
                    disclaimer: this.state.disclaimer,
                    show: this.handleShow,
                    hide: this.handleHide,
                    skip: this.state.skip,
                    title: this.state.title,
                    body: this.state.body,
                    accept: this.handleAccept,
                }}>
                <div className="App">
                    {!!this.state.structure && (
                        <Switch>
                            <Route
                                path="/:id?"
                                render={(props) => (
                                    <Auth {...props}>
                                        <Page
                                            structure={this.state.structure}
                                            id={props.match.params.id || null}
                                            {...props}
                                        />
                                        <Onboarding
                                            structure={this.state.structure}
                                            id={props.match.params.id || null}
                                        />
                                    </Auth>
                                )}
                            />
                        </Switch>
                    )}
                    {this.state.cookies && <Cookies />}
                    <Message />
                    <Splash />
                </div>
            </AppContext.Provider>
        )
    }
}
