import React from 'react'
import cx from 'classnames'

import '../stylesheets/components/Splash.scss'

export default class Splash extends React.Component {
    state = {
        done: process.env.NODE_ENV === 'development' || !!window.localStorage.getItem('splash'),
    }
    handleLoaded = () => {
        window.setTimeout(() => {
            window.localStorage.setItem('splash', 1)
            this.setState({ done: true })
        }, 4000)
    }
    render() {
        return (
            <div className={cx('Splash', { Splash_done: this.state.done })}>
                <div className="Splash_image">
                    <img src="/images/splash.gif" alt="Anapptomy" onLoad={this.handleLoaded} />
                </div>
            </div>
        )
    }
}
