import React from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'

import locale from '../services/locale'
import api from '../services/api'

import '../stylesheets/pages/Code.scss'

export default class Code extends React.Component {
    state = {
        loading: false,
        code: '',
    }
    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({ loading: true })
        api.code(
            new URLSearchParams(this.props.location.search).get('email') || '',
            new URLSearchParams(this.props.location.search).get('token') || '',
            this.state.code,
        )
            .then((response) => {
                this.setState({ loading: false })
                window.localStorage.setItem('token', response.data.token)
                this.props.history.push('/')
            })
            .catch((e) => {
                console.log(e)
                this.setState({ loading: false })
                alert(locale.translate('code_error'))
            })
    }
    handleCodeChange = (e) => {
        this.setState({ code: e.target.value.replace(/\D/g, '').slice(0, 4) })
    }
    render() {
        return (
            <div className="Code">
                <div className="Code_window">
                    <div className="Code_form">
                        <form onSubmit={this.handleSubmit}>
                            <div className="Code_field">
                                <input
                                    type="text"
                                    placeholder={locale.translate('code_code_placeholder')}
                                    value={this.state.code}
                                    onChange={(e) => this.handleCodeChange(e)}
                                />
                            </div>
                            <div className="Code_submit">
                                <button
                                    type="submit"
                                    disabled={this.state.loading || !this.state.code.length}
                                    className={cx({
                                        Code_loading: this.state.loading,
                                    })}>
                                    {locale.translate('code_button')}
                                </button>
                            </div>
                            <div className="Code_link">
                                <p>
                                    {locale.translate('code_link_introduction')}{' '}
                                    <Link to="/login">{locale.translate('code_link_action')}</Link>.
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
