import React from 'react'
import { Redirect } from 'react-router-dom'

import AppContext from '../contexts/AppContext'
import api from '../services/api'

const allowed = ['/login', '/signup', '/code', '/confirm', '/thanks', '/about', '/disclaimer']

export default class Auth extends React.Component {
    static contextType = AppContext
    state = {
        ready: !new URLSearchParams(this.props.location.search).get('launch'),
    }
    componentDidMount = () => {
        if (!this.state.ready) {
            api.launch(new URLSearchParams(this.props.location.search).get('launch') || '')
                .then((response) => {
                    window.localStorage.setItem('token', response.data.token)
                })
                .catch((e) => {
                    console.log(e)
                })
                .finally(() => this.setState({ ready: true }))
        }
    }
    render() {
        if (!this.state.ready) return null
        if (
            !!window.localStorage.getItem('token') ||
            allowed.indexOf(this.props.location.pathname) > -1
        )
            return React.Children.map(this.props.children, (child) => {
                return React.cloneElement(child, { auth: !!window.localStorage.getItem('token') })
            })
        return <Redirect to="/login" />
    }
}
