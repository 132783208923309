import React from 'react'
import cx from 'classnames'

import AppContext from '../contexts/AppContext'
import locale from '../services/locale'

import '../stylesheets/components/Message.scss'

export default class Message extends React.Component {
    state = {}
    static contextType = AppContext
    handleHide = () => {
        if (this.context.skip) this.context.hide()
    }
    render() {
        return (
            <div className={cx('Message', { Message_active: this.context.visible })}>
                <div className="Message_overlay" onClick={this.handleHide} />
                <div className="Message_holder">
                    <div className="Message_window">
                        <div className="Message_body">
                            <h2>{this.context.title}</h2>
                            <p>{this.context.body}</p>
                        </div>
                        {this.context.skip && (
                            <div className="Message_button" onClick={this.handleHide}>
                                <p>{locale.translate('message_button')}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}
