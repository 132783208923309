import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Transition } from 'react-spring/renderprops'

export default class Shift extends React.Component {
    render() {
        return (
            <Transition
                items={this.props.location}
                keys={(location) => location.pathname}
                config={{ duration: 300, easing: (k) => 1 - Math.pow(1 - k, 1.675) }}
                initial={this.props.initial}
                from={this.props.from}
                enter={this.props.enter}
                leave={this.props.leave}>
                {(location) => (style) =>
                    (
                        <Switch location={location}>
                            <Route
                                path="/:id?"
                                render={(props) => {
                                    const id = props.match.params.id || null
                                    return this.props.render(id, style, props)
                                }}
                            />
                        </Switch>
                    )}
            </Transition>
        )
    }
}
