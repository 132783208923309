import React from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'

import locale from '../services/locale'
import api from '../services/api'

import '../stylesheets/pages/Login.scss'

export default class Login extends React.Component {
    state = {
        loading: false,
        email: '',
    }
    handleLogin = (e) => {
        e.preventDefault()
        this.setState({ loading: true })
        api.access(this.state.email)
            .then((response) => {
                this.setState({ loading: false })
                this.props.history.push(
                    `/code?email=${encodeURIComponent(
                        response.data.email,
                    )}&token=${encodeURIComponent(response.data.token)}`,
                )
            })
            .catch((e) => {
                console.log(e)
                this.setState({ loading: false })
                alert(locale.translate('login_error'))
            })
    }
    handleEmailChange = (e) => {
        this.setState({ email: e.target.value })
    }
    render() {
        return (
            <div className="Login">
                <div className="Login_window">
                    <div className="Login_form">
                        <form onSubmit={this.handleLogin}>
                            <div className="Login_link">
                                <p>{locale.translate('login_introduction')}</p>
                                <p>
                                    {locale.translate('login_help')}{' '}
                                    <a href="mailto:info@anpptomy.nl">
                                        {locale.translate('login_contact')}
                                    </a>
                                </p>
                            </div>
                            <div className="Login_field">
                                <input
                                    type="text"
                                    placeholder={locale.translate('login_email_placeholder')}
                                    value={this.state.email}
                                    onChange={(e) => this.handleEmailChange(e)}
                                />
                            </div>
                            <div className="Login_submit">
                                <button
                                    type="submit"
                                    disabled={this.state.loading || !this.state.email.length}
                                    className={cx({
                                        Login_loading: this.state.loading,
                                    })}>
                                    {locale.translate('login_button')}
                                </button>
                            </div>
                            <div className="Login_switch">
                                <p>
                                    {locale.translate('login_hint')}{' '}
                                    <Link to="/signup">{locale.translate('login_switch')}</Link>
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
